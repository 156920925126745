html {
  font-size: 1px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  font-family: 'Open Sans Hebrew', sans-serif;
  /* display: flex;
  flex-direction: column; */
  overflow: hidden;
}

button, input {
  font-family: 'Open Sans', sans-serif;
  font-family: 'Open Sans Hebrew', sans-serif;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

#root {
  flex-basis: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  #backArrow {
    display: none;
  }
 }

 #NagishLiTrigger {
  border-radius: 50%;
  overflow: hidden;
}

 @media only screen and (max-width: 768px) {
  #NagishLiTrigger {
    top: 70px;
    max-width: 40px !important;
    max-height: 40px !important;
    z-index: 2 !important;
  }

  #NagishLiTrigger img{
    width: 100%;
  }
  
  .he #NagishLiTrigger {
    left: 10px;
  }
 }
